import Header from "./components/Header";
import Footer from "./components/Footer";
import Hamburger from "./hamburger";
import "./App.css";
import content from "./content";
import contentProvider from "./utils/contentProvider";

const about = contentProvider(content, 3).about;

function About() {
  return (
    <>
      <Header />
      <main id="pageWrap">
        <div className="App">
          <body className="AppHeader">
            <Hamburger />
            <h1 className="heading">{about?.heading}</h1>
            <h3 className="headingInitials">{about?.heading}</h3>
            <p className="subheading">{about?.heading}</p>
          </body>
          <Footer />
        </div>
      </main>
    </>
  );
}

export default About;
