import { stack as Menu } from "react-burger-menu";
import "./hamburger.style.css";
function Hamburger() {
  return (
    <Menu outerContainerId={"outer-container"} right disableAutoFocus>
      <a href="/">
        <p style={{ margin: "0" }}>Home</p>
      </a>
      <a href="service">
        <p style={{ margin: "0" }}>Service</p>
      </a>
      <a href="/contact">
        <p style={{ margin: "0" }}>Contact</p>
      </a>
      <a href="/about">
        <p style={{ margin: "0" }}>About</p>
      </a>
    </Menu>
  );
}

export default Hamburger;
