import React from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import Home from "./home";
import Contact from "./contact";
import Service from "./service";
import About from "./about";
import Error from "./Error";
import Hamburger from "./hamburger";

export default function App() {
  return (
    <>
      <Hamburger />
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/service" component={Service} />
        <Route path="/contact" component={Contact} />
        <Route path="/about" component={About} />
        <Route component={Error} />
      </Switch>
    </>
  );
}
