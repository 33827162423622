import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css";
import Hamburger from "./hamburger";
import logo from "./logo-dark.png";
import content from "./content.json";
import contentProvider from "./utils/contentProvider";

const home = contentProvider(content, 0).home;

export default function Home() {
  return (
    <>
      <Header />
      <main id="pageWrap">
        <div className="App">
          <body className="AppHeader">
            <Hamburger />
            <img className="AppLogo" src={logo} alt="logo" />
            <h1 className="heading">{home?.heading}</h1>
            <h3 className="headingInitials">{home?.headingInitials}</h3>
            <p className="subheading">{home?.subheading}</p>
          </body>
          <Footer />
        </div>
      </main>
    </>
  );
}
