import React, { useState } from "react";
import josh from "../josh.jpeg";
import contentProvider from "../utils/contentProvider";
import content from "../content.json";
import Divider from "../utils/divider";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "../contact.css";

const contact = contentProvider(content, 2).contact;
export function ContactForm() {
  const [buttonText, setButtonText] = useState("📞 Call me");
  const called = () => setButtonText("Copied! Use your phone 📱");
  return (
    <div className="contact-form">
      <img src={josh} alt="Josh Billson" />
      <h1 className="contact-heading" data-testid="contact-heading">
        {contact?.contactHeading}
      </h1>
      <p className="contact-subheading" data-testid="contact-subheading">
        {contact?.contactSubheading}
      </p>
      <Divider />
      <p className="contact-job" data-testid="contact-job">
        {contact?.contactJob}
      </p>
      <span className="contact-details">
        <CopyToClipboard text={contact?.phone}>
          <button data-testid="call-button" onClick={called}>
            {buttonText}
          </button>
        </CopyToClipboard>
        <button data-testid="mail-button">
          <a href="mailto:billsonfarriery@gmail.com">Email me</a>
        </button>
      </span>
    </div>
  );
}

export function EmbeddedMap() {
  return (
    <div className="maps" data-testid="map">
      <iframe
        src={contact?.map}
        width="550"
        height="400"
        allowFullScreen
        loading="lazy"
        title="Map"
      />
    </div>
  );
}
