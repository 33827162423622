export default function Header() {
  return (
    <header>
      <meta charSet="UTF-8" />
      <link rel="icon" type="image/svg+xml" href="/src/favicon.svg" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>🐴 Billson Farriery 🐴</title>
    </header>
  );
}
