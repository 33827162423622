import "../App.css";

export default function Home() {
  return (
    <>
      <footer className="socials">
        <a
          href="https://www.facebook.com/billsonfarriery/about/?ref=page_internal"
          style={{ textDecoration: "none", color: "#fff" }}
        >
          facebook{" "}
        </a>
        <a
          href="https://www.instagram.com/billsonfarriery/?fbclid=IwAR0WUeZ62k0fgb4FDkqiwfN-roGzWz8MT11nTzjhE6iA4sZ85p6Lbj5Tyk4"
          style={{ textDecoration: "none", color: "#fff" }}
        >
          - instagram{" "}
        </a>
        <a
          id="email"
          href="mailto:billsonfarriery@gmail.com"
          style={{ textDecoration: "none", color: "#fff" }}
        >
          - email
        </a>
      </footer>
    </>
  );
}
