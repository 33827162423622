import Footer from "./components/Footer";
import "./App.css";
import Hamburger from "./hamburger";
import logo from "./logo-dark.png";
import content from "./content.json";
import contentProvider from "./utils/contentProvider";

const error = contentProvider(content, 4).error;

export default function Home() {
  return (
    <>
      <main id="pageWrap">
        <div className="App">
          <body className="AppHeader">
            <Hamburger />
            <img
              className="AppLogo"
              src={logo}
              alt="logo"
              height={400}
              width={400}
            />
            <h1 className="heading">{error?.heading}</h1>

            <p className="subheading">{error?.subheading}</p>
          </body>
          <Footer />
        </div>
      </main>
    </>
  );
}
