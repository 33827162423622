import Header from "./components/Header";
import Footer from "./components/Footer";
import Hamburger from "./hamburger";
import "./contact.css";
import content from "./content";
import contentProvider from "./utils/contentProvider";
import { ContactForm, EmbeddedMap } from "./utils/contact_helper";

const contact = contentProvider(content, 2).contact;

export default function Contact() {
  return (
    <>
      <Header />
      <div id="pageWrap">
        <div className="App" data-testid="contact-page">
          <div className="AppHeader">
            <Hamburger />
            <h1 className="contactTitle" data-testid="contact-heading">
              {contact?.heading}
            </h1>
            <div className="contactBlock" data-testid="contact-block">
              <ContactForm />
              <EmbeddedMap />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
